const searchKeys = [
  // {
  //   value: 'id',
  //   label: 'Id'
  // },
  {
    value: 'naam',
    label: 'Pakket naam'
  },
  {
    value: 'verkoopprijs_totaal',
    label: 'Totaal prijs excl.'
  },
  {
    value: 'verkoopprijs_totaal_incl',
    label: 'Totaal prijs incl.'
  },
  {
    value: 'verkoopprijs_totaal_btw_hoog',
    label: 'Prijs BTW Hoog'
  },
  {
    value: 'verkoopprijs_totaal_btw_laag',
    label: 'Prijs BTW Laag'
  },
  {
    value: 'verkoopprijs_totaal_btw_nul',
    label: 'Prijs BTW nul'
  },
  {
    value: 'klantnummer',
    label: 'Klantnummer'
  },
  {
    value: 'klant.bedrijfsnaam',
    label: 'Klantnaam'
  },
  {
    value: 'artikel.nr',
    label: 'Artikel(nummer)'
  },
  {
    value: 'artikel.omschrijving',
    label: 'Artikel(omschrijving)'
  },
  {
    value: 'artikel.leverancier.artikel_nummer',
    label: 'Artikel(leverancier artikelnr)'
  }
];

const searchKeysOutput = {
  default: 'id',
  searchKeys
};

export default searchKeysOutput;