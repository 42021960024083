import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Table,
  ListGroup,
  FormControl,
  InputGroup,
  Badge
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  FaFileDownload,
  FaGlobeEurope,
  FaMapMarker,
  FaPhone,
  FaPrint,
  FaSave,
  FaUserAlt
} from 'react-icons/fa';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import ApiService from '../../Services/InkoopService';
import inkooporderStatuses from '../../data/inkooporderStatuses';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import InspectButton from '../../Helpers/InspectButton';
import TelButton from '../../Helpers/Buttons/TelButton';
import MailtoButton from '../../Helpers/Buttons/MailtoButton';
import { formatUrl } from '../../Helpers/Helpers';

const InkooporderView = () => {
  const listGroupClasses = 'd-flex justify-content-between flex-wrap flex-md-nowrap align-items-center'
  const [inkooporder, setInkooporder] = useState(null);
  const [leverancier, setLeverancier] = useState(null);
  const [currentStatus, setStatus] = useState(inkooporderStatuses.default);
  const {
    notifications,
    addGenericErrorNotification,
    addSuccessNotification,
    removeNotifications
  } = useAlertNotifications();

  let { ordernummer } = useParams();
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    retrieveFromApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStatus = (status) => {
    for (const [key, value] of Object.entries(inkooporderStatuses)) {
      if (key === status) {
        setStatus(value);
        break;
      }
    }
  };

  const retrieveFromApi = () => {
    ApiService.getInkooporder(ordernummer)
      .then(response => {
        setInkooporder(response.data);
        updateStatus(response.data.status);
        setLeverancier(response.data.leverancier);
        // setArtikelen(response.data.artikelen);
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  };

  const onSubmit = data => {
    const binnengekomen = [];

    data.artikelen.forEach(artikel => {
      const index = data.artikelen.findIndex(a => a === artikel);

      const ontvangen = data.binnengekomen[index];
      const breuk = data.breuk[index];
      const locatie = data.locaties[index];
      const ontvangst = {
        'artikelId': artikel,
        'ontvangen': ontvangen,
        'locatie': locatie,
        'breuk': breuk
      };

      binnengekomen.push(ontvangst);
    });
    reset();
    ApiService.updateVoorraad(ordernummer, binnengekomen)
      .then(response => {
        // setArtikelen(response.data);

        const name = "artikelen";
        const value = response.data;
        setInkooporder({ ...inkooporder, [name]: value});
        retrieveFromApi();
        addSuccessNotification('Inkooporder aangepast.');
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  };

  return (
    <Container fluid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BodyTitleBar title={`Inkooporder #${ordernummer}`}>
          <ButtonGroup>
            <Button
              type="submit"
              variant="success"
            ><FaSave /> Opslaan</Button>
            <Button
              onClick={() => ApiService.downloadPDF(ordernummer, `Inkooporder-${ordernummer}.pdf`)}>
              <FaFileDownload />
            </Button>
            <Button onClick={() => ApiService.printPDF(ordernummer)}>
              <FaPrint />
            </Button>
          </ButtonGroup>
        </BodyTitleBar>
        <AlertNotifications
          notifications={notifications}
          removeNotifications={removeNotifications}
        />
        {inkooporder && (
          <>
            <Row>
              <Col>
                <ListGroup>
                  <ListGroup.Item variant="primary"><strong>Informatie</strong></ListGroup.Item>
                  <ListGroup.Item
                    className={listGroupClasses}
                  >
                    <span>Inkoopordernummer</span>
                    <InspectButton
                      to={`/inkoop/order/${inkooporder.ordernummer}`}
                      variant="outline-primary"
                    >#{inkooporder.ordernummer}</InspectButton>
                  </ListGroup.Item>
                  <ListGroup.Item
                    className={listGroupClasses}
                  >
                    <span>Orderdatum</span>
                    <strong>{inkooporder.orderdatum}</strong>
                  </ListGroup.Item>
                  <ListGroup.Item
                    className={listGroupClasses}
                    variant={currentStatus.variant}
                  >
                    <span>Status</span>
                    <strong>{currentStatus.message}</strong>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              {leverancier && (
                <Col>
                  <ListGroup>
                    <ListGroup.Item variant="primary"><strong>Leverancier</strong></ListGroup.Item>
                    <ListGroup.Item
                      className={listGroupClasses}
                    >
                      <span>
                        Leveranciersnummer:
                      </span>
                      <InspectButton
                        to={`/leveranciers/edit/${leverancier.nummer}`}
                      >#{leverancier.nummer}</InspectButton>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={listGroupClasses}
                    >
                      <span>
                        Bedrijfsnaam:
                      </span>
                      <InspectButton
                        to={`/leveranciers/edit/${leverancier.nummer}`}
                      >{leverancier.naam}</InspectButton>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={listGroupClasses}
                    >
                      <FaMapMarker /> <strong>{leverancier.adres} {leverancier.postcode} {leverancier.plaatsnaam}</strong>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={listGroupClasses}
                    >
                      <span>&nbsp;</span>
                      <span><FaPhone /> {leverancier.telefoonnummer}</span>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={listGroupClasses}
                    >
                      <span>Contact</span>
                      <span><FaUserAlt /> {leverancier.contactpersoon}</span>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={listGroupClasses}
                    >
                      <span>&nbsp;</span>
                      <ButtonGroup>
                        <TelButton
                          variant="outline-info"
                          number={leverancier.telefoonnummer}
                        >{leverancier.telefoonnummer}</TelButton>

                        <MailtoButton
                          variant="outline-info"
                          to={leverancier.emailadres}
                        >{leverancier.emailadres}</MailtoButton>
                      </ButtonGroup>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={listGroupClasses}
                    >
                      <span>Website</span>
                      <span>
                        <a
                          className="btn btn-link"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={leverancier.website}
                        >
                          <FaGlobeEurope />{' '}{formatUrl(leverancier.website)}
                        </a>
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={listGroupClasses}
                    >
                      <span>Webshop</span>
                      <span>
                        {
                          leverancier.webshop
                            ? (
                              <Badge
                                variant="success"
                              >Ja</Badge>
                            )
                            : <Badge
                              variant="danger"
                            >Nee</Badge>
                        }
                      </span>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              )}
            </Row>
            <br />
            <Row>
              <Col>
                <Table responsive>
                  <colgroup>
                    <col span={4} />
                    <col span={1} style={{ borderRight: "3px double #ccc" }} />
                    <col span={2} />
                    <col span={1} style={{ borderRight: "3px double #ccc" }} />
                  </colgroup>
                  <thead className="thead-dark">
                    <tr>
                      <th>#</th>
                      <th>Omschrijving</th>
                      <th>Aantal</th>
                      <th>Besteleenheid</th>
                      <th>Artikelnummer leverancier</th>
                      <th>Voorraad</th>
                      <th>Magazijn locaties</th>
                      <th>Totaal breuk <small>(stuks)</small></th>
                      <th>Verwacht</th>
                      <th>Binnengekomen</th>
                      <th>Magazijn locatie</th>
                      <th>Breuk <small>(stuks)</small></th>
                    </tr>
                  </thead>
                  <tbody>
                    {inkooporder.artikelen && inkooporder.artikelen.map((artikel, index) => {
                      const pivot = artikel.pivot;
                      const verwacht = (pivot.aantal - pivot.ontvangen - pivot.breuk) / artikel.inhoud;
                        return (
                          <tr key={index}>
                            <td>
                              <InspectButton
                                variant='outline-primary'
                                to={`/artikelen/show/${artikel.artikelnr}`}
                              >#{artikel.artikelnr}</InspectButton>
                            </td>
                            <td>{artikel.omschrijving}</td>
                            <td>{pivot.aantal / artikel.inhoud}</td>
                            <td>{artikel.besteleenheid} a {artikel.inhoud}</td>
                            <td>{artikel.leverancier_artikel_nummer}</td>
                            <td>{pivot.ontvangen === 0 ? '-' : pivot.ontvangen}</td>
                            <td>{pivot.locatie === null ? "-" : pivot.locatie}</td>
                            <td>{pivot.breuk === 0 ? '-' : pivot.breuk}</td>
                            <td>{verwacht <= 0 ? 0 : verwacht}</td>
                            <td>
                              <input
                                type="hidden"
                                name={`artikelen[${index}]`}
                                value={artikel.id}
                                ref={register}
                              />
                              <InputGroup>
                                <FormControl
                                  type="text"
                                  name={`binnengekomen[${index}]`}
                                  id="binnengekomen"
                                  ref={register}
                                  placeholder={'-'}
                                  autoComplete={'off'}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text>{artikel.besteleenheid}</InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </td>
                            <td>
                              <FormControl
                                type="text"
                                name={`locaties[${index}]`}
                                id="locatie"
                                ref={register}
                                placeholder={'-'}
                                autoComplete={'off'}
                              />
                            </td>
                            <td>
                              <InputGroup>
                              <FormControl
                                type="text"
                                name={`breuk[${index}]`}
                                id="breuk"
                                ref={register}
                                placeholder={'-'}
                                autoComplete={'off'}
                              />
                                <InputGroup.Append>
                                  <InputGroup.Text>ST</InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </td>
                          </tr>
                        )
                    })}
                  </tbody>
                </Table>

              </Col>
            </Row>
        </>
        )}
      </form>
    </Container>
  );
};

export default InkooporderView;